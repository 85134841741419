<template>
  <div>
    <resource-upload></resource-upload>
  </div>
</template>

<script>
import ResourceUpload from './ResourceUpload.vue';

export default {
  components: {
    ResourceUpload,
  },

  data() {
    return {};
  },
};
</script>
